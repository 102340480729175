<template>
    <div>
        <div class="leisure_button_group_sub">
            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SUREMAN_POWERBALL_1M}"
                         :to="{path:'/leisure/suremanpowerball1m', query: {t: new Date().getTime()}}">파워볼 1분</router-link>

            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SUREMAN_POWERBALL_2M}"
                         :to="{path:'/leisure/suremanpowerball2m', query: {t: new Date().getTime()}}">파워볼 2분</router-link>
            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SUREMAN_POWERBALL_3M}"
                         :to="{path:'/leisure/suremanpowerball3m', query: {t: new Date().getTime()}}">파워볼 3분</router-link>
        </div>
        <div class="leisure_button_group_sub">
            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SUREMAN_SADALI_1M}"
                         :to="{path:'/leisure/suremansadali1m', query: {t: new Date().getTime()}}">사다리 1분</router-link>
            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SUREMAN_SADALI_2M}"
                         :to="{path:'/leisure/suremansadali2m', query: {t: new Date().getTime()}}">사다리 2분</router-link>
            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SUREMAN_SADALI_3M}"
                         :to="{path:'/leisure/suremansadali3m', query: {t: new Date().getTime()}}">사다리 3분</router-link>
        </div>
    </div>



</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompSureManGameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>
.mt-1{
    margin-top: 10px;
}
</style>